<template>
  <div>
   <header-style title="Projects"/>
   <div class="pt-3">
    <div class="d-flex justify-content-between ">
      <div>
      <input-form  v-model="textSearch" placeholder="search" class="btn-search-merchant mt-3"></input-form>
    </div>
      <div class="mt-3 mx-3">
        <!-- <b-button class="btn-filter">  <i class="fas fa-filter"></i>Filter</b-button> -->
        <b-dropdown size="" variant="white2" right text="right align" toggle-class="text-decoration-none" no-caret
          class="p-0" id="dropdown-header-1">
          <template #button-content class="">
            <i class="fas fa-filter"></i> Filter
          </template>
          <div class="" style="width:300px">
            <h6 style="color:#5D636F;font-size: 14px;font-weight: 600; ">Filters</h6>
            <div class="mt-3">
              <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 ">Transaction Amount (SAR)</label>
              <MultiRangeSlider
                baseClassName="multi-range-slider-bar-only"
                :minValue="oBarMinValue2"
                :maxValue="oBarMaxValue2"
                :max="200"
                :min="0"
                :step="0.1"
                :rangeMargin="0"
                @input="update_oBarValues2"
                class="range-slider"
              />
              <div style="display: flex; justify-content: space-between; text-align: center">
                <span style="
                                                  display: inline-block;
                                                  padding: 10px;
                                                  border: solid 1px #D3D6DE;
                                                  border-radius: 5px;
                                                  width: 106.5px;
                                                  height: 44px;
                                                  margin: 3px 5px;
                                                  color: #161A22;
                                                  font-size: 14px;
                                                  font-weight: 600;
                                                ">{{ oBarMinValue2 }}</span>
                <span class="pt-2" style="font-size:18px;font-weight: 600;color: #808693;">To</span>
                <span style="
                                                  display: inline-block;
                                                  padding: 10px;
                                                  border: solid 1px #D3D6DE;
                                                  border-radius: 5px;
                                                  width: 106.5px;
                                                  height: 44px;
                                                  margin: 3px 5px;
                                                  color: #161A22;
                                                  font-size: 14px;
                                                  font-weight: 600;
                                                ">{{ oBarMaxValue2 }}</span>
              </div>
        </div>
        <div>
          <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 " class="mt-3">Transaction Count (SAR)</label>
          <b-form-input v-model="number" placeholder="0.00" style="width:104px;height:44px; margin:0px 3px"></b-form-input>

        </div>
        <div>
          <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 " class="mt-3">Payment Gateways</label>
          <div class="">
            <b-form-checkbox class="pb-1">Noon Pay</b-form-checkbox>
            <b-form-checkbox class="pb-1">HyperPay</b-form-checkbox>
            <b-form-checkbox class="pb-1">PayMob</b-form-checkbox>
            <b-form-checkbox class="pb-1">Fawry</b-form-checkbox>
            <b-form-checkbox class="pb-1">OPay</b-form-checkbox>
          </div>
          <!-- <b-form-input v-model="number" placeholder="0.00" style="width:104px;height:44px; margin:0px 3px"></b-form-input> -->

        </div>
        <div align="end" class="mt-2">
          <b-button class="btn-cancel"  aria-describedby="dropdown-header-1">Cancel</b-button>
          <b-button variant="blue">Apply Filter</b-button>

        </div>

        </div>
        </b-dropdown>
        <b-button variant="blue" class="mx-2"> <i class="fas fa-arrow-circle-down"></i>Export</b-button>
        <b-button class="px-1" variant="green-dark" v-b-modal.modal-4 >
          <i class="fas fa-plus"></i>  Add Project </b-button>
      </div>
    </div>

    <b-modal id="modal-4" title="Add New Project" hide-footer centered size="lg">
      <div>
        <div class="label-name pb-2">
        <h5 class="">project Photo</h5>
        <i class="fas fa-star px-1"></i>
      </div>
        <div class="add-photo ">
          <b-img v-bind="mainProps" alt="Rounded image" class="mb-1 image-user mr-3 " style="border-radius:50%;"></b-img>
          <button class="upload-photo">Upload Photo</button>
        </div>
        <div class="my-3 ">
          <div class="label-name">
            <h6 class="font-title">Project Name</h6>
            <i class="fas fa-star px-1"></i>
          </div>
          <b-form-input v-model="text" placeholder="Project Name" class="mt-2 input-modal-project"></b-form-input>
          <div class="label-name mt-3">
            <h6 class="font-title ">Project Description</h6>
            <i class="fas fa-star px-1"></i>
          </div>
          <b-form-textarea id="textarea-no-resize" placeholder="Fixed height textarea" rows="5" no-resize
            class="mt-2"></b-form-textarea>
          <div class="label-name mt-3">
            <h6 class="font-title ">Assign To</h6>
            <i class="fas fa-star px-1"></i>
          </div>
          <b-form-input v-model="text" placeholder="Assign to a user" class="mt-2 input-modal-project"></b-form-input>
        </div>
        <div  align="end" class="mt-3 mb-2" >
      <b-button class="btn-cancel" >
        Cancel</b-button>
      <b-button variant="green-dark" class="btn-add" > Add Project</b-button>
    </div>
      </div>
    </b-modal>
   </div>

    <b-row cols="3" class="card-project">
   <div class="pt-3 "  v-for = "project in projects" :key = "project" >
    <router-link :to="{ name: 'projectDetails' }" >
  <b-card no-body class="overflow-hidden  py-3 rounded" style="max-width:490px;" >
<b-col >
      <b-row no-gutters>
      <b-col md="2">
        <b-card-img :src= project.image  alt="Image" class="rounded-4" style="max-width: 80%;"></b-card-img>
      </b-col>
      <b-col md="10">
          <h5 class="dafa-project">{{ project.project_title}}</h5>
            <span v-if="project.description">
              {{ project.description}}
            </span>
            <span v-else>
               ---
            </span>
            <span >
        </span>
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <h6 class="title-payment">TRX Amount</h6>
      </b-col>
      <b-col>
        <h6 class="title-payment">TRX Count</h6>
      </b-col>
      <b-col>
        <h6 class="title-payment">Merchants</h6>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col>
        <h6 class="price-payment">{{ project.total_transactions_amount}} SAR</h6>
      </b-col>
      <b-col>
        <h6 class="price-payment">{{ project.total_transactions_counts}}</h6>
      </b-col>
      <b-col>
        <h6 class="price-payment">{{ project.total_merchants}}</h6>
      </b-col>
    </b-row>
    <div class="pt-3">
      <p style="color: #808693;">
        Payment Gateway
      </p>
      <b-row>
        <b-col md="5">
          <img src="../../assets/images/noonPay.png" alt="" style="width:32px;height: 32px; border-radius: 50%; margin: 4px;">
          <span>Noon Pay</span>

        </b-col>
        <b-col md="7">
          <img src="../../assets/images/hyperPay.png" alt="" style="width:40px;height: 40px;">
          <span>Hyper Pay</span>
        </b-col>
        </b-row>
    </div></b-col>
  </b-card>
  </router-link>
</div>
</b-row>

  </div>
</template>
<script>
import projectService from './services/project'
import MultiRangeSlider from 'multi-range-slider-vue'
import 'multi-range-slider-vue/MultiRangeSliderBlack.css'
import 'multi-range-slider-vue/MultiRangeSliderBarOnly.css'
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  components: { HeaderStyle, MultiRangeSlider },
  name: 'Projects',

  data () {
    return {
      oBarMinValue2: 1,
      oBarMaxValue2: 90,
      projects: [],
      mainProps: { blank: true, blankColor: '#777', width: 120, height: 120, class: 'm1' }
      // payments: [
      //   { title: 'DAFA Payment(DPA)', name: 'Mohamed Gamal', desc1: 'TRX Amount', desc2: 'TRX Count', desc3: 'Merchants', price1: '20.000', price2: '250', price3: '20', gateways: 'Payment Gateways', gateway1: 'Noon Pay', gateway2: 'HyperPay', imagepay1: require('../../assets/images/noonPay.png'), imagepay2: require('../../assets/images/hyperPay.png') },
      //   { title: 'DAFA Payment(DPA)', name: 'Mohamed Adel', desc1: 'TRX Amount', desc2: 'TRX Count', desc3: 'Merchants', price1: '15.000', price2: '250', price3: '20', gateways: 'Payment Gateways', gateway1: 'OPay', imagepay1: require('../../assets/images/opay.png') },
      //   { title: 'DAFA Payment(DPA)', name: 'Mohamed Adel', desc1: 'TRX Amount', desc2: 'TRX Count', desc3: 'Merchants', price1: '200.000', price2: '250', price3: '20', gateways: 'Payment Gateways', gateway1: 'Paymob', imagepay1: require('../../assets/images/paymob.png') }
      // ]
    }
  },
  methods: {
    update_oBarValues2 (e) {
      this.oBarMinValue2 = e.minValue
      this.oBarMaxValue2 = e.maxValue
    }
  },
  created () {
    projectService.findAll().then(response => {
      this.projects = response.data.data
      this.loading = false
    })
  }
}
</script>

<style lang="scss" >
.card-project{
  margin-inline-end: 0px !important;
  margin-inline-start: 10px !important;
}

.price-payment{
  font-weight: 700;
  font-size: 14px;
}
.title-payment{
  font-weight: 600;
  font-size: 13px;
  color: #808693;
}
.dafa-project{
font-weight: 700;
font-size: 16px;
color: #161A22;
}
.upload-photo{
  border: 1px solid #E1E3E8;
  background: #F7F9FA;
  border-radius: 4px;
  padding: 8px 16px;
  width: 147px;
}
.input-modal-project{
  padding: 20px 10px !important;
  margin: 10px 0px !important;
  border: 0.75px solid #D3D6DE;
  height: 48px;
  width: 100% !important;
}
.input-desc{
  height: 140px !important;
}
.btn-cancel{
background-color:transparent !important;
color: #5D636F;
width: 67px;
height: 30px;
border: none;
margin-inline-end: 25px;
}
.btn-cancel:hover{
  background-color: transparent !important;
  color: black;
}
.btn-cancel:focus{
  color: black;
}
.label-name{
  display: flex;
  align-items: center;
  i{
    color: #f0416c;
    width: 90px;
    height: 22px;
    font-size: 8px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.MultiRangeSliderContainer {
  margin: auto;
  width: 800px;
}
.multi-range-slider-bar-only .bar-inner {
    background-color: #0A7EBA;
    border: solid 1px #E7F2F8;
    box-shadow: none;
    height:6px;
}
.multi-range-slider-bar-only .bar-left {
    width: 25%;
    height: 4px;
     background-color: #E7F2F8;
     border-radius: 10px 0px 0px 10px;
    box-shadow: none;
    padding: 2px 0px;
}
.multi-range-slider-bar-only .bar-right {
    width: 25%;
    height: 4px;
     background-color: #E7F2F8;
     border-radius: 10px 0px 0px 10px;
    box-shadow: none;
    padding: 2px 0px;

}
.multi-range-slider-bar-only .thumb::before {
    content: '';
    background-color: #0A7EBA;
    position: absolute;
    width: 14px;
    height: 14px;
    border: none;
    box-shadow: none;
    border-radius: 50%;
    z-index: 1;
    margin: -4px;
    cursor: pointer;
}

</style>
