import api from '@/axios'

export default {
  findAll () {
    return api().get('projects')
  },
  findOne (id) {
    return api().get(`projects/${id}`)
  }
}
